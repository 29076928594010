//Styles for page loader
.page-loader {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  background-color: #41b292;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 150px;
    @include mobile {
      margin-bottom: 60%;
    }
  }
  transform: translateY(-100vh);
  transition: transform 0.5s ease-in;
}

.page-loader-open {
  transform: translateY(0vh);
  transition: transform 0.5s ease-in;
}
