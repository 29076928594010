// Icon
.hamburger {
  position: fixed;
  z-index: 850;
  top: 25px;
  right: 25px;
  cursor: pointer;
  & img {
    height: 48px;
    transition: all 0.2s ease-out;
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }

  & .mC {
    display: none;
  }

  & .mO {
    display: block;
  }

  @include mobile {
    top: 22px;
    right: 20px;
    & img {
      height: 42px;
    }
  }
}

.hamburger--active {
  background-color: transparent;
  & .mC {
    display: block;
  }

  & .mO {
    display: none;
  }
}

/* Floating Menu*/
#float-menu {
  position: fixed;
  z-index: 600;
  top: 90px;
  right: 30px;
  text-align: left;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 0.6em;
  font-weight: 900;
  letter-spacing: 0.2em;

  & li {
    float: left;
    padding-bottom: 0.2em;
    & a {
      transition: all 0.2s ease-in-out;
      color: rgba(0, 0, 0, 0.5);
      &:hover {
        color: #e2e1cf;
      }
    }
  }

  & .active a {
    color: #e2e1cf;
  }

  // work line changue on active element
  & .active ul {
    & li a hr {
      background-color: #e2e1cf;
    }
    &.linesWork {
      display: flex;
    }
  }

  & .linesWork {
    font-size: 0.6em;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    display: none;

    & a {
      color: rgba(0, 0, 0, 0.5);
    }

    & hr {
      height: 2px;
      //color: white;
      // background-color: rgba(0, 0, 0, 0.2);
      width: 15px;
      margin: 0.6em 5px;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: white;
        width: 25px;
      }
    }
  }
}

//Extension de la barra en el menu flotante para cada trabajo
.fp-viewing-works-guido .hrGu {
  width: 25px !important;
}
.fp-viewing-works-skyhawk .hrSk {
  width: 25px !important;
}
.fp-viewing-works-ga .hrGa {
  width: 25px !important;
}
.fp-viewing-works-more .hrMo {
  width: 25px !important;
}

.fp-viewing-tp-0 {
  #float-menu li:first-child a {
    color: #e2e1cf;
  }
  .hrT {
    width: 25px !important;
  }
}

.fp-viewing-tp-1 {
  #float-menu li:first-child a {
    color: #e2e1cf;
  }
  .hrP {
    width: 25px !important;
  }
}

/* Nav */
.menu-overlay nav {
  text-align: center;
  position: relative;
  top: 50%;
  height: 60%;
  @include mobile {
    text-align: left;
  }
}

.menu-overlay ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  height: 100%;
  position: relative;
  text-align: left;
}

.menu-overlay ul li {
  display: block;
  height: 20%;
  height: calc(100% / 5);
  // min-height: 54px;
  line-height: 1.2;
}

.menu-overlay ul li a {
  font-size: 54px;
  font-weight: 300;
  display: block;
  color: #f37377;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  @include mobile {
    font-size: 40px;
  }
}

.menu-overlay ul li a:hover,
.menu-overlay ul li a:focus {
  color: #f0f0f0;
}

.btn-home-menu {
  @extend .m-t-lg, .m-b-lg;
  font-size: 1.2em;
  color: #502e3f;
  @include my-box-btn(130px, 35px, #fac903, $bg-menu, #f37377, 6px, false);
  & a {
    color: #502e3f;
  }
  @include mobile {
    margin-bottom: 0;
  }
}

.lineMenu {
  background-color: #fac903;
  height: 4px;
  margin: 1.5rem 80% 1.5rem 0px;
}

.menuContact {
  line-height: 2;
  @extend .is-size-4;

  & h3 {
    color: #3e5873;
  }
  & a {
    color: #e2e1cf;
  }
  & a:hover {
    color: white;
  }
  @include mobile {
    line-height: 1;
    & h3 {
      font-size: 1rem;
      padding-top: 10px;
    }
    & a {
      font-size: 1rem;
    }
  }
}

.langMenu {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100px;
  top: 85px;
  font-family: "Bebas Neue";

  & .itemLang {
    font-size: 1.2em;
    font-weight: 900;
    padding-right: 20px;

    &:hover img {
      width: 2.8em;
    }
  }

  & a {
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
    color: #3e5873;
    letter-spacing: 0.1em;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  & a:hover {
    color: white;
  }
  & img {
    width: 2.5em;
    padding-bottom: 0.5em;
    transition: all 0.2s ease-in-out;
  }

  @include mobile {
    top: 25px;
  }
}

/* Overlay */
.menu-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
  z-index: 800;
}

.menu-overlay-contentpush {
  background: $bg-menu;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: -webkit-transform 0.5s, visibility 0s 0.5s;
  transition: transform 0.5s, visibility 0s 0.5s;
}

.menu-overlay-contentpush.open {
  visibility: visible;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
}

@media screen and (max-height: 30.5em) {
  .menu-overlay nav {
    height: 70%;
    font-size: 34px;
  }
  .menu-overlay ul li {
    min-height: 34px;
  }
}
