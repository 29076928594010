// Syles for fadding on fullpage.js

.fullpage-wrapper {
  @include tablet {
    .fp-section {
      .fp-slidesContainer {
        width: 100% !important;
        //transform: none !important;
        background-color: black;
        .fp-slide {
          &.active {
            visibility: visible;
            opacity: 1;
            z-index: 1;
          }
          width: 100% !important;
          position: absolute;
          left: 0;
          top: 0;
          visibility: hidden;
          opacity: 0;
          z-index: 0;
          transition: all 0.8s ease-in-out;
        }
      }
    }
  }
}

//Change background for about Team Process
.fp-viewing-tp-0 {
  .fullpage-wrapper {
    @include tablet {
      .fp-section {
        .fp-slidesContainer {
          background-color: #5ac7a8;
        }
      }
    }
  }
}

.fp-viewing-tp-1 {
  .fullpage-wrapper {
    @include tablet {
      .fp-section {
        .fp-slidesContainer {
          background-color: #5ac7a8;
        }
      }
    }
  }
}
