// Import Fonts

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

@font-face {
  font-family: "CircularStdBook";
  src: url("/assets/fonts/Circular-Std-Book.ttf") format("truetype");
}

@font-face {
  font-family: "CrimsonTextReg";
  src: url("/assets/fonts/CrimsonText-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "BebasNeue-Bold";
  src: url("/assets/fonts/BebasNeue-Bold.otf");
}
