// Import libraries
@import "../../node_modules/normalize-scss/sass/normalize";
@import "./boxSizingFix.scss";
@import "./fonts.scss";
@import "./mixinsWeb.scss";

// COLORS
$bg-hero: #5ac7a8;
$bg-servA: #f37377;
$bg-servB: #f38285;
$bg-process: #fcb410;
$bg-works: #1c3562;
$bg-works2: #2b2b2b;
$bg-workdetail: #dc6854;
$bg-contact: #5ac7a8;
$bg-contact-r: #4db799;
$bg-menu: #17283a;

// Bulma
@import "../../node_modules/bulma/bulma.sass";
// spacing helper for Bulma
@import "./spacing.scss";

//Custom Styles
@import "./fadeFP.scss";
@import "./menu.scss";
@import "./pageLoader.scss";

// ----------------------------------------- //

body {
  font-family: "CircularStdBook";
  min-width: 100px !important;
}

.container {
  @include mobile {
    // padding-left: 5%;
    padding: 5%;
  }
}

.is-fullheight {
  min-height: 100vh;
  display: flex;
  align-items: center;

  @include mobile {
    // min-height: 100px;
    min-height: inherit;
    // align-items: inherit;
  }
}

a {
  transition: all 0.2s ease-in-out;
}

//BRAND ICON
.brandIcon {
  position: fixed;
  z-index: 550;
  top: 25px;
  left: 25px;

  & img {
    height: 60px;
    transition: all 0.2s ease-out;
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
    @include mobile {
      height: 50px;
    }
  }
  @include mobile {
    top: 20px;
    left: 20px;
    position: absolute !important;
  }
}

//HOME
.illHome {
  min-width: 100%;
  height: 60vh;
  background-image: url(../assets/images/home-pre.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  @include mobile {
    height: 40vh;
    margin-top: 15%;
  }
}

.titleHome {
  font-size: 2.3rem;
  // @extend .is-size-2;
  font-family: "CircularStdBook";
  color: #17283a;
  font-weight: 700;

  & span {
    color: #e2e1cf;
  }

  @include mobile {
    font-size: 1.5rem;
  }
  @media only screen and (max-height: 570px) {
    font-size: 1.2rem;
  }
}

.subtitleHome {
  @extend .is-size-2;
  font-family: "CrimsonTextReg";
  color: #8de2ca;
  @include mobile {
    font-size: 1.5rem !important;
  }
}

//SAFARI HACK
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .m-revcol {
      height: 80vh;
    }
  }
}

@include mobile {
  .m-revcol {
    flex-direction: column-reverse;
    display: flex;
    & .heroTitle {
      margin-top: -15%;
    }
  }
}

//SERVICE
.services {
  color: #17283a;

  & h1 {
    @extend .is-size-3;
    color: #e2e1cf;
    font-weight: 700;
    width: 250px;
    margin: auto;
  }
  & hr {
    background-color: #f4a0a2;
    height: 4px;
    margin: 1.5rem 45% 1.5rem 45%;
  }
  & ul li {
    @extend .is-size-5;
    font-weight: 900;
    line-height: 1.8;
  }

  & .btn-serv {
    margin-top: 2rem !important;
    @include my-box-btn(140px, 30px, #fac903, $bg-servA, #502e3f, 6px, true);
  }

  @include mobile {
    padding: 10%;

    & h1 {
      font-size: 1.8rem !important;
    }
    & ul li {
      font-size: 1rem !important;
    }
    & hr {
      height: 3px;
      margin: 1.5rem 35% 1rem 35%;
    }
  }
}

//US -> SECTION
.illUs {
  width: 100%;
  height: 60vh;
  background-image: url(../assets/images/us-pre.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  @include mobile {
    height: 40vh;
    margin-top: 10%;
  }
}

.usHomeLeft {
  @include mobile {
    align-items: center !important;
  }
}

.usHomeRight {
  & h1 {
    @extend .is-size-3, .m-b-md;
    font-family: "CircularStdBook";
    color: #502e3f;
    font-weight: 900;
  }

  & p {
    @extend .is-size-5, .m-b-lg;
    color: #17283a;
    font-weight: 100;
  }

  & .btn-us-home {
    @include my-box-btn(140px, 30px, #f37377, $bg-process, #502e3f, 6px, false);
    // padding-bottom: 10%;
    & a {
      color: #502e3f;
    }
  }

  @include mobile {
    padding: 5% !important;

    & h1 {
      font-size: 1.5rem !important;
      font-weight: 900;
    }

    & p {
      font-size: 1rem !important;
      font-weight: 100;
    }
  }
}

//US -> PAGE
.about-us {
  background-color: #5ac7a8;
  width: 100%;
  overflow: hidden;
}

.usMenu {
  background-color: $bg-contact-r;

  & .wrpMenuUs {
    padding-left: 33.333%;
    padding-top: 10%;
    height: 40vh;
    width: 90%;

    & .wrpLinkUs {
      height: 80%;
    }
  }

  & .itemMenuSelected {
    @extend .is-size-3;

    & a {
      // background-color: #fac903;
      border-bottom: 4px solid #fac903;
    }
  }
  & .itemMenu {
    @extend .p-b-lg;
    @extend .is-size-3;
    font-family: CircularStdBook;
    font-weight: 600;
    color: #17283a;

    & a {
      color: #17283a;
      padding: 0px 10px 0 0px;
    }
  }
  & .btn-about-us {
    @include my-box-btn(
      140px,
      30px,
      #fac903,
      $bg-contact-r,
      #502e3f,
      6px,
      false
    );
    & a {
      color: #502e3f;
    }
  }
}

.usTeam {
  background-color: $bg-contact;

  &.active {
    display: flex;
  }

  & .teamName {
    & .teamMember:first-child {
      @extend .p-b-xl, .p-t-md;
    }

    & .teamMember {
      color: #502e3f;

      & h2 {
        @extend .is-size-5;
        @extend .p-b-md;
        font-weight: 900;
      }

      & p {
        @extend .is-size-6;
        line-height: 1.5em;
        color: #17283a;
      }
    }
  }

  & .columns {
    position: relative;
    width: 100%;
  }
  & .illusTeam {
    position: absolute;
    left: 25%;
    width: 90%;
    height: 50vh;
    top: -10%;
    background-image: url(../assets/images/ep-pre.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.usProcess {
  background-color: $bg-contact;

  display: none;

  &.active {
    display: flex;
  }

  & .columns {
    color: #502e3f;
    width: 100%;
    @extend .p-t-xl;

    & .column p:last-child {
      @extend .p-b-lg;
    }

    & .column {
      & h3 {
        @extend .is-size-5, .p-b-lg;
        text-transform: uppercase;
      }
      & p {
        font-size: 0.8rem !important;
        color: #17283a;
        width: 80%;
      }
    }
  }

  & .itemPr {
    display: flex;
    flex-direction: row;
    position: relative;

    & h3 {
      font-weight: 900;
    }

    font-weight: 900;

    & img {
      position: absolute;
      height: 6em;
      bottom: 1em;
      left: 6em;
    }
  }
}

.usMobile {
  & .teamName {
    & .teamMember {
      color: #502e3f;
      & h2 {
        font-size: 1rem !important;
        padding-top: 1.5rem;
        // padding-bottom: 0.5rem;
        font-weight: 900;
      }
    }
  }
  & p {
    @extend .is-size-6;
    line-height: 1.5em;
    color: #17283a;
  }
  & .illusTeam-mob {
    margin-top: 15%;
    width: 90%;
    margin-left: 5%;
    height: 40vh;
    background-image: url(../assets/images/ep-pre.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  & .titleTeam-mob {
    font-size: 1.8rem;
    font-weight: 900;
    padding-bottom: 0.5rem;
    color: #17283a;
  }
  & .titleProcess-mob {
    font-size: 1.8rem;
    font-weight: 900;
    padding-bottom: 2.5rem;
    color: #17283a;
  }
  & h3 {
    @extend .is-size-5, .p-t-md, .p-b-md;
    text-transform: uppercase;
    font-weight: 900;
    color: #502e3f;
  }
  & .itemPr {
    & img {
      // position: absolute;
      margin-top: 10%;
      height: 5em;
      bottom: 1em;
      left: 6em;
    }
  }
  & .btn-home-about-mob {
    margin-left: 5%;
    @extend .m-t-lg, .m-b-lg;
    font-size: 1.2em;
    color: #502e3f;
    @include my-box-btn(
      150px,
      35px,
      #fac903,
      $bg-contact-r,
      #562b3e,
      6px,
      false
    );
    & a {
      color: #502e3f;
    }
  }
}

//WORKS
.worksCont {
  & .column:first-child {
    // background-color: grey;
    height: 72vh;
    padding: 0px;
    & video {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  & .column:nth-child(2) {
    position: relative;

    & .workInfo {
      position: absolute;
      top: -18vh;
      left: -150px;
      width: 300px;
      background-color: #e2e1cf;
      padding: 2em;

      & h2 {
        font-size: 1.5em;
        font-weight: 600;
      }

      & h3 {
        font-size: 1em;
        color: grey;
        font-weight: 400;
      }
      & p {
        @extend .p-t-md, .p-b-md;
        font-size: 0.8em;
      }
      & a {
        color: inherit;
      }
    }
  }

  @include mobile {
    .workInfo {
      // position: absolute;
      top: -5vh;
      left: 10%;
      width: 80%;
      background-color: #e2e1cf;
      padding: 2em;
      z-index: 50;
      position: relative;

      & h2 {
        font-size: 1.5em;
        font-weight: 600;
      }

      & h3 {
        font-size: 1em;
        color: grey;
        font-weight: 400;
      }
      & p {
        // @extend .p-t-md, .p-b-md;
        padding: 1rem 0;
        font-size: 0.8em;
      }
      & a {
        color: inherit;
      }
    }
    .wrp-img {
      z-index: 40;
    }
  }
}

.wk-guido {
  background-color: $bg-works;
  @include mobile {
    padding: 0;
  }
  & .worksCont {
    & .workInfo {
      box-shadow: -25px 25px 0px 0px #1c3562;
      color: #1c3562;
      @include mobile {
        box-shadow: none;
      }
      & .btn-launch-guido {
        @include my-box-btn(
          100px,
          30px,
          #e2e1cf,
          #e2e1cf,
          #1c3562,
          4px,
          false,
          true
        );
      }
    }
  }
}

.wk-sky {
  background-color: #2b2b2b;
  @include mobile {
    padding: 0;
  }
  & .worksCont {
    & .workInfo {
      box-shadow: -25px 25px 0px 0px #2b2b2b;
      color: #2b2b2b;
      @include mobile {
        box-shadow: none;
      }
      & .btn-launch-sky {
        @include my-box-btn(
          100px,
          30px,
          #e2e1cf,
          #e2e1cf,
          #2b2b2b,
          4px,
          false,
          true
        );
      }
    }
  }
}

.wk-ga {
  background-color: #3a3a47;
  @include mobile {
    padding: 0;
  }
  & .worksCont {
    & .workInfo {
      box-shadow: -25px 25px 0px 0px #3a3a47;
      color: #3a3a47;
      @include mobile {
        box-shadow: none;
      }
      & .btn-launch-sky {
        @include my-box-btn(
          100px,
          30px,
          #e2e1cf,
          #e2e1cf,
          #3a3a47,
          4px,
          false,
          true
        );
      }
    }
  }
}

.wk-more {
  background-color: #502e3f;
  & h2 {
    text-align: center;
    color: #fcb410;
    @extend .is-size-3, .m-lg;
    font-weight: 900;
  }
  & .columns .column {
    text-align: center;
  }

  & .imgWrp {
    height: 15vh;
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
    padding: 0px 50px;
    margin: 50px 0px;
    & img {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }

  & .column.is-1 {
    width: 12.5% !important;
  }
}

//CONTACT

.rightContact {
  color: #17283a;
  background-color: $bg-contact-r;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .wrpContactB {
    padding-left: 8.33%;
    & .titleContact {
      @extend .p-b-xl, .is-size-2;
    }

    & h1 {
      @extend .is-size-3;
      @extend .p-b-lg;
      font-family: CircularStdBook;
      font-weight: 600;
    }

    & h3 {
      @extend .is-size-4;
      @extend .p-b-md;
    }
    & a {
      color: white;
      @extend .is-size-4;
    }
    & a:hover {
      color: #e2e1cf;
    }

    & footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;

      & ul {
        margin: 0 2em;
        & li {
          line-height: calc(80px / 3);
          letter-spacing: 0.1em;
        }
      }
      & a {
        color: #17283a;
        @extend .is-size-7;
        font-weight: 900;
      }
      & a img {
        height: 80px;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }

      & .vl {
        border-left: 1px solid #17283a;
        height: 80px;
        opacity: 0.3;
      }

      & .copyLeft {
        margin: 0 2em;
        & h5 {
          @extend .is-size-7;
          @extend .p-b-md;
          align-items: flex-start;
        }
        & p {
          font-size: 0.6rem;
          line-height: 2em;
          & a {
            font-size: 0.6rem;
            font-weight: 100;
          }
        }
      }
    }
  }
}

.leftContact {
  background-color: $bg-contact;
  flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .wrpContactA {
    width: 80%;
    padding: 0 10%;

    & label {
      font-size: 1rem;
      color: #17283a;
    }
    & input {
      background-color: transparent;
      border-radius: 0px;
      outline: 0;
      border-width: 0 0 1px;
      border-color: #3e9c8f;
      box-shadow: none;
      color: #17283a;
      padding-left: 0px;

      &:hover {
        border-color: #3e9c8f;
      }

      &:focus {
        color: #17283a;
        border-color: #3e9c8f;
      }
    }

    & .twoFields {
      display: flex;
      flex-direction: row;

      & .A {
        width: 40%;
      }
      & .B {
        width: 60%;
      }

      & .select {
        width: 100%;

        &::after {
          border-color: #17283a;
        }

        & select {
          background-color: transparent;
          border-radius: 0px;
          outline: 0;
          border-width: 0 0 1px;
          border-color: #3e9c8f;
          box-shadow: none;
          color: #17283a;
          width: 100%;
          padding-left: 0px;
        }
      }

      & .wrpRadioBtn {
        height: 2.5em;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #3e9c8f;
        padding-left: 0px;

        & .radio {
          justify-content: center;
        }

        & input[type="radio"] {
          visibility: hidden;
          position: absolute;
        }
        input[type="radio"] + label {
          position: relative;
          width: 33%;
          color: #17283a;
        }

        & input[type="radio"] + label:before {
          content: "";
          display: inline-block;
          height: 15px;
          width: 15px;
          background: #3d9a8e;
          margin-right: 0.2em;
        }

        input[type="radio"]:checked + label:after {
          content: "✓";
          color: #17283a;
          z-index: 99;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    & textarea {
      background-color: transparent;
      border-radius: 0px;
      outline: 0;
      border-width: 0 0 1px;
      border-color: #3e9c8f;
      box-shadow: none;
      color: #17283a;
      resize: none;
      min-height: 6em !important;
      padding-left: 0px;

      &:hover {
        border-color: #3e9c8f;
      }

      &:focus {
        color: #17283a;
        border-color: #3e9c8f;
      }
    }

    & .btn-send-contact {
      @extend .m-t-md;
      @include my-box-btn(
        100px,
        30px,
        #fac903,
        $bg-contact,
        #502e3f,
        6px,
        false
      );
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #3e9c8f;
      opacity: 1; /* Firefox */
      padding-left: 0px;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #3e9c8f;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #3e9c8f;
    }
  }

  @include mobile {
    // background-color: $bg-contact-r;

    & .titleContact {
      font-size: 1.5rem;
      font-weight: 700;
      padding-bottom: 2rem;
    }
    & .wrp-contact-mob {
      padding-top: 2rem;
      & p {
        font-size: 0.7rem;
        & a {
          font-size: 0.7rem;
          font-weight: 100;
        }
      }
    }
    & .wrpContactA {
      color: #17283a;
      width: 100%;
      padding: 10%;

      & label {
        font-weight: 100;
      }

      & h3 {
        font-size: 1.2rem;
        font-weight: 100;
      }

      & a {
        color: white;
        font-size: 1.2rem;
      }
    }
  }
}

// 404
.fof {
  background-color: #5ac7a8;
  width: 100%;
  overflow: hidden;

  & .illFof {
    width: 100%;
    height: 30vh;
    @include mobile {
      margin-top: 10%;
    }
  }

  & .title {
    @extend .is-size-3;
    color: #17283a;
    font-weight: 900;
    padding-bottom: 1rem;
    @include mobile {
      font-size: 1.5rem !important;
    }
  }

  & .subtitle {
    @extend .is-size-2;
    font-family: "CrimsonTextReg";
    color: #8de2ca;
    font-weight: 900;
    padding-bottom: 1rem;
    @include mobile {
      font-size: 1.5rem !important;
    }
  }

  & .btn-fof {
    @include my-box-btn(120px, 40px, #fcb410, #5ac7a8, #502e3f, 6px, false);
    & a {
      color: #502e3f;
    }
  }
}

.privacy {
  @extend .fof;
  @include mobile {
    padding-top: 100px;
  }
}

// Background Colors
.bg-hero {
  background-color: $bg-hero;
}
.bg-servA {
  background-color: $bg-servA;
}
.bg-servB {
  background-color: $bg-servB;
}
.bg-process {
  background-color: $bg-process;
}
.bg-works {
  background-color: $bg-works;
}
.bg-workdetail {
  background-color: $bg-workdetail;
}
.bg-contact {
  background-color: $bg-contact;
}
.bg-contact-r {
  background-color: $bg-contact-r;
}
